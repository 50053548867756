import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatDate, log_err, log_msg } from '../lib/functions';
import { isError, isYnabApiError, isYnabSdkError } from '../lib/typeguards';
import { config } from '../lib/config';
import { categoryStore } from '../storeTypes';
import { AppThunk } from '../types';
import { categoryGroupActions } from './categoryGroups';
import { sessionActions } from './session';
import * as ynab from 'ynab';
import { Category, CategoryGroupWithCategories } from 'ynab';

// It's important to initialize this store with the right "dummy" category.  This is used in other
// parts of the app to detect when it is necessary to go to YNAB for the true list of categories.
const dummyCategory: Category = {
  activity: 0,
  balance: 0,
  budgeted: 0,
  category_group_id: '1',
  deleted: false,
  hidden: false,
  id: 'Dummy Category',
  name: 'Dummy Category',
};

const initialState: categoryStore = {
  categories: [dummyCategory],
  lastUpdated: 0,
  retrieving: false,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      // Start by clearing out all categories.  If this is called, it means it's
      // getting a fresh list of categories.  I don't want to start appending
      // to the old list, I want to overwrite it.
      state.categories = [];

      for (const category of action.payload) {
        state.categories.push(category);
      }

      const now = new Date().getTime();
      state.lastUpdated = now;
    },
    setLoggedOut: (state): void => {
      state.categories = [dummyCategory];
      state.lastUpdated = 0;
      state.retrieving = false;
    },
    setRetrieving: (state, action: PayloadAction<boolean>): void => {
      state.retrieving = action.payload;
    },
    updateCategory: (state, action: PayloadAction<Category>): void => {
      const idx = state.categories.findIndex(
        (cat) => cat.id === action.payload.id
      );

      // TODO: Test the transfer functionality for error handling by
      // making sure that the category is not found.  Could do this
      // by appending something to the category id to make sure that
      // it doesn't exist in the array.

      if (idx !== -1) {
        state.categories[idx] = action.payload;
      } else {
        throw new Error(
          `Category could not be found using ID ${action.payload.id}`
        );
      }
    },
  },
});

export const refreshYnabCategories =
  (): AppThunk => async (dispatch, getState) => {
    const { account, categories } = getState();
    const {
      budgetId,
      retrieving: retrievingAccount,
      reAuthorizingWithYnab,
    } = account;
    const { ynabAccessToken } = account.ynabTokens;
    const { retrieving: retrievingCategories } = categories;

    // Only get categories if I'm not in the middle of retrieving the account,
    // and I'm not in the process of reauthorizing with YNAB, and I have a
    // budgetId, and I'm not already in the middle of retrieving categories.
    const execute =
      !retrievingAccount &&
      !reAuthorizingWithYnab &&
      budgetId &&
      !retrievingCategories;

    if (execute) {
      // I'm not sure I love this, but I need to reset the error when the interval runs, otherwise
      // previous errors will keep the UI from responding to future successful calls.  I've seen this
      // when a call is made using an expired YNAB access token.  The code will catch that, refresh
      // the token, make a good call to get categories, but the UI continues to show the error.  The
      // problem here is that I lose the historical record that the error occurred.  That migth be fine.
      dispatch(sessionActions.setError(''));
      dispatch(categoriesActions.setRetrieving(true));

      try {
        const ynabApi = new ynab.API(ynabAccessToken);

        const categoryResponse = await ynabApi.categories.getCategories(
          budgetId
        );
        const categories = categoryResponse.data.category_groups;

        // console.log(JSON.stringify(categoryResponse.data));

        const categoryList: Category[] = [];
        const categoryGroupList: CategoryGroupWithCategories[] = [];

        for (const group of categories) {
          // Skip over any category groups that are hidden or deleted
          if (group.hidden || group.deleted) {
            continue;
          }

          // Add this group to the array of groups
          categoryGroupList.push(group);

          for (const category of group.categories) {
            // Skip over any categories that are hidden or deleted
            if (category.hidden || category.deleted) {
              continue;
            }

            categoryList.push(category);
          }
        }

        if (categoryGroupList.length > 0 && categoryList.length > 0) {
          dispatch(categoryGroupActions.setCategoryGroups(categoryGroupList));
          dispatch(categoriesActions.setCategories(categoryList));
          log_msg(
            `Categories refreshed. [now=${formatDate(new Date())}]`,
            String(config.commentStyleYnab)
          );
        }
      } catch (error) {
        let message = '';
        let exceptionToThrow = undefined;

        // TODO: Clean this up

        if (isYnabSdkError(error)) {
          message = `RequiredError from the YNAB SDK refreshYnabCategories: '${error.field}', ${error.message}`;
        } else if (isYnabApiError(error)) {
          if (error.error.detail === 'Unauthorized') {
            message =
              'It looks like a call was made to the YNAB API with tokens that are expired.  This should not happen.  Check the logs for the refreshYnabTokens cron job.';
          } else {
            message = `The YNAB API returned an error that was not 'Unauthorized'.  Error=${JSON.stringify(
              error
            )}`;
          }
        } else if (isError(error)) {
          message = `Error from the YNAB SDK refreshYnabCategories: '${error.message}'`;
        } else {
          message = `An error happened in refreshYnabCategories (categories.ts) that is apparently not a YNAB SDK error nor a standard JavaScript Error object.  So it will be re thrown so it's not lost.  Details=${String(
            error
          )}`;

          // Since the error is a mystery, I want to throw it and not swallow
          // it up.  This variable will be tested later to see if there is
          // anything to throw.
          exceptionToThrow = error;
        }

        log_err(message);

        dispatch(
          sessionActions.setError(
            'We could not get your categories for some reason.  You might want to try again later.'
          )
        );

        if (exceptionToThrow !== undefined) {
          throw exceptionToThrow;
        }
      } finally {
        dispatch(categoriesActions.setRetrieving(false));
      }
    }
  };

export const categoriesActions = categoriesSlice.actions;

export default categoriesSlice.reducer;
