import { FirebaseOptions, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { config } from './config';

const firebaseOptions: FirebaseOptions = {
  apiKey: String(config.firebaseApiKey),
  authDomain: String(config.firebaseAuthDomain),
  databaseURL: String(config.firebaseDatabaseUrl),
  projectId: String(config.firebaseProjectId),
  storageBucket: String(config.firebaseStorageBucket),
  messagingSenderId: String(config.firebaseMessagingSenderId),
  appId: String(config.firebaseAppId),
  measurementId: String(config.firebaseMeasurementId),
};

const app = initializeApp(firebaseOptions);

export const auth = getAuth(app);
export const database = getDatabase(app);

if (String(config.environment) === 'local') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectDatabaseEmulator(database, 'localhost', 9000);
}
