// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { config } from './src/lib/config';
//import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: String(config.sentryDsn),

  // This enables automatic instrumentation (highly recommended), but is not
  // necessary for purely manual usage
  // integrations: [new BrowserTracing({
  //   tracingOrigins: ["localhost", "usersforynab.com", "preview.usersforynab.com", /^\//]
  // })],

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,

  // When set to true, the SDK will send session events to Sentry. This is supported in all browser SDKs, emitting one session per pageload and page navigation to Sentry. In mobile SDKs, when the app goes to the background for longer than 30 seconds, sessions are ended.
  autoSessionTracking: true,

  environment: String(config.environment),

  //release: process.env.VERSION,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
