import type { NextPage } from 'next';
import Link from 'next/link';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { setStoreLoggedOut } from '../../store/store';

const Header: NextPage = () => {
  const role = useAppSelector((state) => state.user.role);
  const [signedIn, setSignedIn] = useState(false);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);

  useEffect(() => {
    setSignedIn(isLoggedIn);
  }, [isLoggedIn]);

  const handleLogout = () => {
    // Note that there is no need to redirect the user to the login page.  The
    // ProtectedRoute.tsx component will trigger when isLoggedIn changes state,
    // will see that the user is no longer logged in despite being on a
    // protected page, and will redirect the user.
    try {
      dispatch(setStoreLoggedOut());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm bg-dark navbar-dark mb-3">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <FontAwesomeIcon icon={faUsers} />
            <span className="ms-2">Users for YNAB</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              {signedIn && role === 'administrator' && (
                <>
                  <li className="nav-item">
                    <Link href="/admin/users" passHref>
                      <a className="nav-link">View Users</a>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link href="/admin/user/add" passHref>
                      <a className="nav-link">Add User</a>
                    </Link>
                  </li>
                </>
              )}
              {signedIn && (
                <li className="nav-item" onClick={handleLogout}>
                  <a className="nav-link" href="#">
                    Log Out
                  </a>
                </li>
              )}
              {!signedIn && (
                <>
                  <li className="nav-item">
                    <Link href="/signup" passHref>
                      <a className="nav-link">Sign Up</a>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link href="/login" passHref>
                      <a className="nav-link">Log In</a>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
