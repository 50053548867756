import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoryGroupWithCategories } from 'ynab';
import { categoryGroupStore } from '../storeTypes';

// It's important to initialize this store with the right "dummy" category.  This is used in other
// parts of the app to detect when it is necessary to go to YNAB for the true list of categories.
const dummyCategoryGroup: CategoryGroupWithCategories = {
  categories: [],
  id: '1',
  deleted: false,
  hidden: false,
  name: 'Dummy Category Group',
};

const initialState: categoryGroupStore = {
  categoryGroups: [dummyCategoryGroup],
  lastUpdated: 0,
};

const categoryGroupSlice = createSlice({
  name: 'categoryGroups',
  initialState,
  reducers: {
    setCategoryGroups: (
      state,
      action: PayloadAction<CategoryGroupWithCategories[]>
    ) => {
      state.categoryGroups = action.payload;

      const now = new Date().getTime();
      state.lastUpdated = now;
    },
    setLoggedOut: (state): void => {
      state.categoryGroups = [dummyCategoryGroup];
      state.lastUpdated = 0;
    },
  },
});

export const categoryGroupActions = categoryGroupSlice.actions;

export default categoryGroupSlice.reducer;
