import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { accountType, ynabTokens } from '../types';
import { accountStore } from '../storeTypes';

const initialState: accountStore = {
  accountId: '',
  budgetId: '',
  hasYnabTokens: false,
  mustReauthorize: false,
  //budgetSettings: 'budgetSettingsType',
  reAuthorizingWithYnab: false,
  retrieving: false,
  ynabTokens: {
    ynabAccessToken: '',
    ynabAccessTokenExpirationDate: 0,
    ynabRefreshToken: '',
  },
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<accountType>): void => {
      state.accountId = action.payload.accountId;
      state.budgetId = action.payload.budgetId;
      state.mustReauthorize = action.payload.mustReauthorize;
      state.reAuthorizingWithYnab = action.payload.reAuthorizingWithYnab;
      state.ynabTokens.ynabAccessToken =
        action.payload.ynabTokens.ynabAccessToken;
      state.ynabTokens.ynabAccessTokenExpirationDate =
        action.payload.ynabTokens.ynabAccessTokenExpirationDate;
      state.ynabTokens.ynabRefreshToken =
        action.payload.ynabTokens.ynabRefreshToken;
    },
    setAccountId: (state, action: PayloadAction<string>) => {
      state.accountId = action.payload;
    },
    setHasYnabTokens: (state, action: PayloadAction<boolean>): void => {
      state.hasYnabTokens = action.payload;
    },
    setLoggedOut: (state): void => {
      state.accountId = '';
      state.budgetId = '';
      state.hasYnabTokens = false;
      state.mustReauthorize = false;
      state.budgetSettings = undefined;
      state.reAuthorizingWithYnab = false;
      state.retrieving = false;
      state.ynabTokens.ynabAccessToken = '';
      state.ynabTokens.ynabAccessTokenExpirationDate = 0;
      state.ynabTokens.ynabRefreshToken = '';
    },
    setReAuthorizingWithYnab: (state, action: PayloadAction<boolean>): void => {
      state.reAuthorizingWithYnab = action.payload;
    },
    setRetrieving: (state, action: PayloadAction<boolean>): void => {
      state.retrieving = action.payload;
    },
    setYnabTokens: (state, action: PayloadAction<ynabTokens>): void => {
      state.ynabTokens.ynabAccessToken = action.payload.ynabAccessToken;
      state.ynabTokens.ynabAccessTokenExpirationDate =
        action.payload.ynabAccessTokenExpirationDate;
      state.ynabTokens.ynabRefreshToken = action.payload.ynabRefreshToken;
    },
  },
});

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;
