import React from 'react';
import Header from './Header';
import type { NextPage } from 'next';
import Footer from './Footer';

const Layout: NextPage = (props) => {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
};

export default Layout;
