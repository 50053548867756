import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import { utils } from 'ynab';
import { config } from './config';
import { GeneralError, GeneralErrorTypes } from '../errorTypes';
import { isFirebaseAuthError, isGeneralError } from './typeguards';

/**
 * This function will accept a number representing a date/time in milliseconds (e.g. Date.getTime()), and return the number of milliseconds
 * between now and the provided date.  This is handy when determining whether a date/time is in the past or future.
 *
 * @param {number} expirationDate The expiration date to use in the check.
 * @param {number} minimumNumber - Optional.  Used to provide a minimum number, which can be useful when calculating a time to use in a setTimeout call.
 * @returns {number} The number of milliseconds between now and the provided date.  The provided date should also be expressed in milliseconds.
 */
export const calculateRemainingTime = (
  expirationDate: number,
  minimumNumber?: number
): number => {
  const currentDate = new Date().getTime();
  let remainingTime = expirationDate - currentDate;

  // This is here as an option for times when I need to make sure I don't return a number that is "too low."  An example
  // use case is when calculating a value to use in a setTimeout.  If I have a date that is too far in the past, this will
  // return a number that is "too negative" and the setTimeout will never fire.  The expected behavior is that it will
  // fire immediately.  Since a value of zero is essentiall the same as a negative number, when I'm calling this function
  // to use with setTimeout, I will pass a zero into this so that I will never return less than zero.  This seems like a
  // good way to make this logic reusable rather than making this change everwhere the I use a setTimeout.
  if (minimumNumber !== undefined) {
    remainingTime =
      remainingTime > minimumNumber ? remainingTime : minimumNumber;
  }

  return remainingTime;
};

/**
 * This function accepts a number in milliunits and converts it to the proper
 * number format as configured in the user's YNAB budget settings.
 *
 * @param {number} number The value to convert from milliunits
 * @returns {number} The converted value
 */
export const convertFromMilliUnits = (number: number): number => {
  //const settings = this.$store.getters['ynab/budgetSettings'];
  //const decimalDigits = settings.currency_format.decimal_digits;
  const decimalDigits = 2;

  return utils.convertMilliUnitsToCurrencyAmount(number, decimalDigits);
};

/**
 * This function accepts a number and converts it to milliunits.  There's a
 * good chance that this is unecessary to have in a function, but I'm doing
 * this in case I end up needing to insert any logic into this conversion.
 *
 * @param {number} number The value to convert from milliunits
 * @returns {number} The converted value
 */
export const convertToMilliUnits = (number: number): number => {
  return number * 1000;
};

export const getError = (object: unknown): GeneralError => {
  let e: GeneralError;
  const genericUserMessage = `Oops, something went wrong behind the scenes.  We'll take a look at it as soon as possible.  Please try again later!`;

  if (isFirebaseAuthError(object)) {
    // Based on the code provided, determine the best messages to provide
    const [userMessage, devMessage] = convertFirebaseErrorCode(
      object.code,
      genericUserMessage,
      object.message
    );

    e = new GeneralError(
      GeneralErrorTypes.FirebaseError,
      object.code,
      500,
      userMessage,
      devMessage,
      object
    );
  } else if (axios.isAxiosError(object)) {
    let userMessage = '';
    let devMessage = '';
    let errorCode = '';
    let httpStatusCode = 500;

    // See if the AxiosError contains a Firebase auth error
    if (
      object.response &&
      object.response.data &&
      isFirebaseAuthError(object.response.data)
    ) {
      const firebaseError = object.response.data;
      [userMessage, devMessage] = convertFirebaseErrorCode(
        firebaseError.code,
        genericUserMessage,
        'It looks like something failed during an Axios call.  This is the generic devMessage from the isAxiosError typeguard in getError.'
      );
      console.log('userMessage: ', userMessage);
    } else {
      // Since it's not a Firebase auth error, get more error details.
      switch (object.message) {
        case 'Request failed with status code 500':
          userMessage = `Uh oh, we got an error back from the server.  We'll take a look as soon as possible.  Please try again later.`;
          devMessage = `An AxiosError occurred, but there was no conditional to catch it.  Probably should check the logs and see if you can update getError with a more descriptive message.`;
          errorCode = object.message;
          httpStatusCode = object.response ? object.response.status : 500;
          break;
        case 'NetworkError':
          userMessage = `Uh oh, we could not connect.  Might you be offline?`;
          devMessage = `Looks like the connection couldn't be made, which generated an Axios network error.  Probably should check the logs and see if you can update getError with a more descriptive message.`;
          errorCode = 'NetworkError';
          break;
      }
    }

    e = new GeneralError(
      GeneralErrorTypes.AxiosError,
      errorCode,
      httpStatusCode,
      userMessage,
      devMessage,
      object
    );
  } else if (isGeneralError(object)) {
    e = object;
  } else {
    e = new GeneralError(
      GeneralErrorTypes.Unknown,
      'Error with no Typeguard',
      500,
      `Well shoot.  An error happened, and I don't know exactly what to do.  We'll look at it ASAP.  Please try again later.`,
      `Need to figure out what happened and then update the geError function so that it's known next time.`,
      object
    );
  }

  return e;
};

/**
 * Looks for a value localStorage and returns it if found.
 *
 * @param {string} key The name of the key representing the value to be retrieved from localStorage
 * @returns {T | null} An object of type T
 * @template T
 */
export const getFromLocalStorage = <T>(key: string): T | null => {
  const stringValue = localStorage.getItem(key);

  if (!stringValue) return null;

  try {
    const returnObj = JSON.parse(stringValue) as T;
    return returnObj;
  } catch {
    return null;
  }
};

/**
 * Use this function to log messages.  It will send the error to the correct output depending on the environment.
 *
 * @param {string} message Any string.
 * @param {style} style An optional string to indicate a style that is compatible with Chrome console styling
 * @returns {void}
 */
export const log_msg = (message: string, style?: string): void => {
  const env = String(config.environment);

  const sentryMessage = message;

  if (env === 'production') {
    Sentry.captureMessage(sentryMessage);
  } else if (env === 'preview') {
    //Sentry.captureMessage(sentryMessage);
    style ? console.log(`%c${message}`, style) : console.log(message);
  } else {
    style ? console.log(`%c${message}`, style) : console.log(message);
  }
};

/**
 * Use this function to log error messages.  It will send the error to the correct output depending on the environment.
 *
 * @param {unknown} error Any error object or string
 * @returns {void}
 */
export const log_err = (error: unknown): void => {
  const env = String(config.environment);

  if (env === 'production') {
    Sentry.captureException(error);
  } else if (env === 'preview') {
    //Sentry.captureException(error);
    console.error(error);
  } else {
    console.error(error);
  }
};

/**
 * Use this function to return a properly formatted currency string.
 *
 * @param {number} number A numeric value to convert to a currency
 * @param {boolean} includePositiveSign Indicates if the function should return the currency with a positive sign.  Negative is already returned.
 * @returns {string} A string that is formatted correctly
 */
export const formatCurrency = (
  number: number,
  includePositiveSign?: boolean
): string => {
  //const settings = this.$store.getters['ynab/budgetSettings'];
  //const isoCode = settings.currency_format.iso_code;
  const isoCode = 'USD';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: isoCode,
  });

  const currency = convertFromMilliUnits(number);

  let returnValue = formatter.format(currency);

  if (includePositiveSign && number > 0) {
    returnValue = `+${returnValue}`;
  }

  return returnValue;
};

/**
 * Use this function to return a consistently formatted date, usually for use in logging.
 *
 * @param {Date} date The date object to format
 * @returns {string} A string that is formatted correctly
 */
export const formatDate = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
  });

  return formatter.format(date);
};

/**
 * Converts the Firebase auth and SDK codes to more useful messages.
 *
 * @param {string} code The code that came from the Firebase error object.
 * @param {string} defaultUserMessage A default user message to return if the Firebase error code is not one that has been defined in this function yet.
 * @param {string} defaultDevMessage A default dev message to return if the Firebase error code is not one that has been defined in this function yet.
 * @returns {string[]} The userMessage and devMessage.
 */
const convertFirebaseErrorCode = (
  code: string,
  defaultUserMessage: string,
  defaultDevMessage: string
): Array<string> => {
  let userMessage = '';
  let devMessage = '';

  switch (code) {
    case 'auth/email-already-exists':
      userMessage =
        'Uh oh, that email address has already been used.  Please try a new one.';
      devMessage =
        'Someone tried to create a user using an email address that already exists.';
      break;
    case 'auth/invalid-password':
      userMessage =
        'Uh oh, that is not a valid password.  Your password must meet the complexity requirements.';
      devMessage = `Someone tried to use a password that doesn't meet the password requirements.`;
      break;
    case 'auth/wrong-password':
      userMessage = 'Uh oh, your password is incorrect.  Please try again.';
      devMessage = 'Someone tried to use an incorrect password.';
      break;
    case 'auth/invalid-argument':
      userMessage =
        "Uh oh, we ran into a snag.  We're aware of it and will get it fixed as soon as we can.";
      devMessage =
        'The Firebase auth call threw and auth/invalid-argument error.  Not sure what the problem is, but check the logs.';
      break;
    case 'auth/invalid-email':
      userMessage =
        "Uh oh, it looks like the email address you're using isn't the right format.  Please try again.";
      devMessage = 'Someone is trying to use a poorly formatted email address.';
      break;
    case 'auth/maximum-user-count-exceeded':
      userMessage =
        "Uh oh, we were not able to sign you up.  We'll take a look as soon as possible.  Pleas try back later.";
      devMessage =
        'The Firebase auth call threw and auth/maximum-user-count-exceeded error.  Surprising, so check the logs.';
      break;
    case 'auth/project-not-found':
      userMessage =
        "Uh oh, we hit a snag, but we'll take a look at it as soon as possible.  Please try again later.";
      devMessage =
        'The Firebase auth call threw and auth/project-not-found error.  Not sure what the problem is, but check the logs.';
      break;
    case 'auth/user-disabled':
      userMessage =
        'Well this is awkward.  Your user account has been disabled.';
      devMessage = 'A user tried to log in with a disabled user account.';
      break;
    default:
      userMessage = defaultUserMessage;
      devMessage = defaultDevMessage;
  }

  return [userMessage, devMessage];
};
