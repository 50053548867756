import type { NextPage } from 'next';
import Link from 'next/link';
import React, { useState } from 'react';
import { calculateRemainingTime, formatDate } from '../../lib/functions';
import { useAppSelector } from '../../hooks/storeHooks';

const Footer: NextPage = () => {
  const accountId = useAppSelector((state) => state.account.accountId);
  const isConnected = useAppSelector((state) => state.session.isConnected);
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const userId = useAppSelector((state) => state.user.localId);
  const budgetId = useAppSelector((state) => state.account.budgetId);
  const consoleMessages = useAppSelector(
    (state) => state.session.consoleMessages
  );

  const ynabAccessToken = useAppSelector(
    (state) => state.account.ynabTokens.ynabAccessToken
  );
  const ynabAccessTokenExpirationDateValue = useAppSelector(
    (state) => state.account.ynabTokens.ynabAccessTokenExpirationDate
  );
  const ynabAccessTokenExpirationDate = formatDate(
    new Date(ynabAccessTokenExpirationDateValue)
  );
  const ynabAccessTokenTimeRemaining =
    calculateRemainingTime(ynabAccessTokenExpirationDateValue, 0) / 1000;

  const ynabRefreshToken = useAppSelector(
    (state) => state.account.ynabTokens.ynabRefreshToken
  );

  const category_group_id = useAppSelector(
    (state) => state.user.category_group_id
  );

  const lastCategoryUpdate = useAppSelector(
    (state) => state.categories.lastUpdated
  );

  const autoLogoutDateValue = useAppSelector(
    (state) => state.session.autoLogOutDate
  );
  const autoLogoutDate = formatDate(new Date(autoLogoutDateValue));
  const autoLogoutDateTimeRemaining =
    calculateRemainingTime(autoLogoutDateValue, 0) / 1000;

  const [showDebug, setShowDebug] = useState(false);

  const showDebugHandler = () => {
    setShowDebug((prevDebug) => !prevDebug);
  };

  return (
    <footer className="footer mt-auto py-2 bg-light">
      {showDebug && (
        <div className="container-fluid bg-secondary text-light fs-6">
          <div>isConnected: {String(isConnected)}</div>

          <div>accountId: {accountId}</div>
          <div>budgetId: {budgetId}</div>
          <div>userId: {userId}</div>
          <div>isLoggedIn: {String(isLoggedIn)}</div>

          <div className="pt-2">YNAB Access Token: {ynabAccessToken}</div>

          <div className="pt-2">
            YNAB Access Token Exp. Date: {ynabAccessTokenExpirationDate} (
            {ynabAccessTokenExpirationDateValue}, {ynabAccessTokenTimeRemaining}{' '}
            seconds remaining)
          </div>

          <div className="pt-2">YNAB Refresh Token: {ynabRefreshToken}</div>

          <div className="pt-2">Category Group Id: {category_group_id}</div>
          <div className="pt-2">
            Categories updated: {formatDate(new Date(lastCategoryUpdate))}
          </div>

          <div className="pt-2">
            Logout: {autoLogoutDate}({autoLogoutDateValue},{' '}
            {autoLogoutDateTimeRemaining} seconds remaining)
          </div>

          <div className="pt-2">
            Console:
            <div className="ps-2 bg-dark text-white">
              <small
                dangerouslySetInnerHTML={{
                  __html: consoleMessages.join('<br/>'),
                }}
              ></small>
            </div>
          </div>
        </div>
      )}{' '}
      <div className="container">
        <div className="row justify-content-evenly">
          <div className="col-4 text-center">
            <Link href="/privacy">
              <a className="text-muted">
                <small>Privacy</small>
              </a>
            </Link>
          </div>
          <div className="col-4 text-center">
            <Link href="/contact">
              <a className="text-muted fs-6">
                <small>Contact</small>
              </a>
            </Link>
          </div>
          <div className="col-4 text-center">
            <div className="text-muted fs-6" onClick={showDebugHandler}>
              <u>
                <small>Debug</small>
              </u>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
