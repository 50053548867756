import { configureStore } from '@reduxjs/toolkit';
import { AppThunk } from '../types';

import accountReducer, { accountActions } from './account';
import categoriesReducer, { categoriesActions } from './categories';

import sessionReducer, { sessionActions } from './session';
import userReducer, { userActions } from './user';
import categoryGroupsReducer, { categoryGroupActions } from './categoryGroups';
import { auth } from '../lib/firebase';

const store = configureStore({
  reducer: {
    account: accountReducer,
    categories: categoriesReducer,
    categoryGroups: categoryGroupsReducer,
    session: sessionReducer,
    user: userReducer,
  },
});

export const setStoreLoggedOut = (): AppThunk => async (dispatch) => {
  dispatch(accountActions.setLoggedOut());
  dispatch(categoriesActions.setLoggedOut());
  dispatch(categoryGroupActions.setLoggedOut());
  dispatch(sessionActions.setLoggedOut());
  dispatch(userActions.setLoggedOut());
  await auth.signOut();
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
