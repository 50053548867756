import type { NextPage } from 'next';
import { useAppDispatch, useAppSelector } from '../hooks/storeHooks';
import React, { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import { refreshYnabCategories } from '../store/categories';

const Ynab: NextPage = (props) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const retrievingAccount = useAppSelector((state) => state.account.retrieving);
  const ynabAccessTokenExpirationDate = useAppSelector(
    (state) => state.account.ynabTokens.ynabAccessTokenExpirationDate
  );
  const budgetId = useAppSelector((state) => state.account.budgetId);
  const role = useAppSelector((state) => state.user.role);
  const mustReauthorize = useAppSelector(
    (state) => state.account.mustReauthorize
  );
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const hasYnabTokens = useAppSelector((state) => state.account.hasYnabTokens);

  /**
   * On the initial page load (or on a page refresh), get the categories.  I need to wait
   * for budgetId to be set, simply as an indicator that state has been updated.  Without
   * this check, the thunk will get called before state has been updated and the thunk will
   * always see the default state.
   */
  useEffect(() => {
    if (budgetId && !mustReauthorize) {
      dispatch(refreshYnabCategories());
    }
  }, [dispatch, budgetId, mustReauthorize]);

  /**
   * This effect hook will catch the situation when there is no budgetId or when there is a
   * budgetId but there is no category groups and categories.
   */
  useEffect(() => {
    // As long as we are logged in with a valid YNAB access token (the
    // timeRemainingOnAccessToken test), have a need to choose a budget
    // (the budgetId test), and are not already on the "choose a budget"
    // or logout page.  Also, don't bother with this if the account is
    // still being retrieved.  Skipping that condition can cause the choose
    // screen to show briefly.
    if (!retrievingAccount && hasYnabTokens && !budgetId) {
      if (role === 'administrator') {
        if (router.route !== '/admin/ynab/budget/choose') {
          void Router.replace('/admin/ynab/budget/choose');
        }
      } else {
        if (router.route !== '/budget/choose') {
          void Router.replace('/budget/choose');
        }
      }
    }
  }, [
    budgetId,
    hasYnabTokens,
    retrievingAccount,
    role,
    router.route,
    ynabAccessTokenExpirationDate,
  ]);

  /**
   * This effect hook will watch for an accountId, which means that the
   * database was queried and we have "real" data.  It is necessary to watch
   * for the accountId otherwise this effect hook would trigger instantly when
   * it finds no tokens.  Once it finds an accountId and NO tokens, it will
   * redirect the browser to the reauthorize page.
   */
  useEffect(() => {
    if (isLoggedIn && mustReauthorize) {
      // Based on the user's role, determine where to redirect them.
      const destination =
        role === 'administrator' ? '/admin/ynab/reauthorize' : '/reauthorize';

      void Router.replace(destination);
    }
  }, [isLoggedIn, mustReauthorize, role]);

  return <>{props.children}</>;
};

export default Ynab;
