/**
 * Given environment variable name and the value found when executing
 * process.env.[environmentVariable], return a string value.
 *
 * @param {string} environmentVariable The name of the environment variable to be retrieved
 * @param {string | undefined} environmentVariableValue The value found as passed using process.env.[variable name]
 * @param {'string'} toType The data type we want to return
 */
function getEnvironmentVariable(
  environmentVariable: string,
  environmentVariableValue: string | undefined,
  toType: 'string'
): string;
/**
 * Given environment variable name and the value found when executing
 * process.env.[environmentVariable], return a number value.
 *
 * It is important to note that the value of a toType of 'number' will
 * always be an integer because there is no primitive in TypeScript for
 * a floating point number.
 *
 * @param {string} environmentVariable The name of the environment variable to be retrieved
 * @param {string | undefined} environmentVariableValue The value found as passed using process.env.[variable name]
 * @param {'number'} toType The data type we want to return
 */
function getEnvironmentVariable(
  environmentVariable: string,
  environmentVariableValue: string | undefined,
  toType: 'number'
): number;
/**
 * Given environment variable name and the value found when executing
 * process.env.[environmentVariable], return a boolean value.
 *
 * @param {string} environmentVariable The name of the environment variable to be retrieved
 * @param {string | undefined} environmentVariableValue The value found as passed using process.env.[variable name]
 * @param {'boolean'} toType The data type we want to return
 */
function getEnvironmentVariable(
  environmentVariable: string,
  environmentVariableValue: string | undefined,
  toType: 'boolean'
): boolean;
/**
 * Overloaded function that will look for a given environment variable and
 * return either a number or boolean.  It is important to note that the value
 * of a toType of 'number' will always be an integer because there is no
 * primitive in TypeScript for a floating point number.
 *
 * @param {string} environmentVariable The name of the environment variable to be retrieved
 * @param {string | undefined} environmentVariableValue The value found as passed using process.env.[variable name]
 * @param {'number' | 'boolean' | 'string'} toType The data type we want to return
 * @returns {string} The strongly typed return value
 */
function getEnvironmentVariable(
  environmentVariable: string,
  environmentVariableValue: string | undefined,
  toType?: 'number' | 'boolean' | 'string'
) {
  const isBrowser = typeof window !== 'undefined';
  const skipCheck =
    isBrowser && !environmentVariable.startsWith('NEXT_PUBLIC_');

  // If the environment variable is not found, throw an error.  However, if
  // the execution is happening in the browser, we need to only throw and
  // error if the variable starts with NEXT_PUBLIC_.  If it doesn't, then
  // it is normal for it to be 'undefined'
  if (skipCheck) {
    return undefined;
  } else if (!environmentVariableValue) {
    throw new Error(
      `Couldn't find environment variable: ${environmentVariable}`
    );
  } else {
    // const msg = `var=${environmentVariable}, in_type=${typeof environmentVariableValue}, in_value=${environmentVariableValue}, `;

    if (toType === 'number') {
      const retVal = Number.parseInt(environmentVariableValue);

      if (isNaN(retVal)) {
        throw new Error(
          `The value "${environmentVariableValue}" cannot be represented as a number.`
        );
      }

      // console.log(msg + `out_type=${typeof retVal}, out_value=${retVal}`);
      return retVal;
    } else if (toType === 'boolean') {
      // Should evaluate to true unless the string is anything other than 'true'
      const retVal = environmentVariableValue.toLowerCase() === 'true';
      // console.log(
      //   msg + `out_type=${typeof retVal}, out_value=${String(retVal)}`
      // );
      return retVal;
    } else {
      const retVal = environmentVariableValue;
      // console.log(msg + `out_type=${typeof retVal}, out_value=${retVal}`);
      return retVal;
    }
  }
}

export const config: { [key: string]: string | number | boolean } = {
  // Environment variables controlling the style of the output in the console
  commentStyleTimer: 'color:#F00',
  commentStyleAuth: 'color:#0A0',
  commentStyleUser: 'color:#00F',
  commentStyleAccount: 'color:#0AA',
  commentStyleYnab: 'color:#AA0',

  // Endpoint for creating a user in Firebase auth
  createUserUri: getEnvironmentVariable(
    'NEXT_PUBLIC_CREATE_USER_URI',
    process.env.NEXT_PUBLIC_CREATE_USER_URI,
    'string'
  ),

  // Endpoint for creating a user in Firebase auth
  deleteUserUri: getEnvironmentVariable(
    'NEXT_PUBLIC_DELETE_USER_URI',
    process.env.NEXT_PUBLIC_DELETE_USER_URI,
    'string'
  ),

  // Indicates which environment we are in (local, staging, production)
  environment:
    getEnvironmentVariable(
      'NEXT_PUBLIC_ENVIRONMENT',
      process.env.NEXT_PUBLIC_ENVIRONMENT,
      'string'
    ) ?? '',

  // Sentry
  sentryDsn:
    getEnvironmentVariable(
      'NEXT_PUBLIC_SENTRY_DSN',
      process.env.NEXT_PUBLIC_SENTRY_DSN,
      'string'
    ) ?? '',

  // Firebase auth
  firebaseAuthDomain:
    getEnvironmentVariable(
      'NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN',
      process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      'string'
    ) ?? '',

  // Firebase database
  firebaseDatabaseUrl:
    getEnvironmentVariable(
      'NEXT_PUBLIC_FIREBASE_DATABASE_URL',
      process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      'string'
    ) ?? '',

  // Firebase miscellaneous
  firebaseStorageBucket:
    getEnvironmentVariable(
      'NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET',
      process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      'string'
    ) ?? '',
  firebaseMessagingSenderId: getEnvironmentVariable(
    'NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID',
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    'number'
  ),
  firebaseApiKey:
    getEnvironmentVariable(
      'NEXT_PUBLIC_FIREBASE_API_KEY',
      process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      'string'
    ) ?? '',
  firebaseProjectId:
    getEnvironmentVariable(
      'NEXT_PUBLIC_FIREBASE_PROJECT_ID',
      process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      'string'
    ) ?? '',
  firebaseAppId:
    getEnvironmentVariable(
      'NEXT_PUBLIC_FIREBASE_APP_ID',
      process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      'string'
    ) ?? '',
  firebaseMeasurementId:
    getEnvironmentVariable(
      'NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID',
      process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
      'string'
    ) ?? '',

  // YNAB config values
  ynabClientId:
    getEnvironmentVariable(
      'NEXT_PUBLIC_YNAB_CLIENT_ID',
      process.env.NEXT_PUBLIC_YNAB_CLIENT_ID,
      'string'
    ) ?? '',
  ynabRedirectUri:
    getEnvironmentVariable(
      'NEXT_PUBLIC_YNAB_REDIRECT_URI',
      process.env.NEXT_PUBLIC_YNAB_REDIRECT_URI,
      'string'
    ) ?? '',
};
