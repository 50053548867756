import { ref, onValue } from 'firebase/database';
import type { NextPage } from 'next';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/storeHooks';
import { database } from '../lib/firebase';
import { accountActions } from '../store/account';
import { sessionActions } from '../store/session';
import { accountType } from '../types';

const Account: NextPage = (props) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const isConnected = useAppSelector((state) => state.session.isConnected);
  const retrievingAccount = useAppSelector((state) => state.account.retrieving);
  const accountId = useAppSelector((state) => state.account.accountId);
  const budgetId = useAppSelector((state) => state.account.budgetId);
  const ynabAccessToken = useAppSelector(
    (state) => state.account.ynabTokens.ynabAccessToken
  );
  const ynabRefreshToken = useAppSelector(
    (state) => state.account.ynabTokens.ynabRefreshToken
  );

  useEffect(() => {
    if (isConnected && isLoggedIn && accountId) {
      // dispatch(
      //   sessionActions.addConsoleMessage(
      //     `onValue subscribed in Account.tsx (accounts/[accountId])`
      //   )
      // );

      dispatch(accountActions.setRetrieving(true));

      const dbRef = ref(database, `accounts/${accountId}`);
      const unsubscribe = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            // dispatch(
            //   sessionActions.addConsoleMessage(
            //     `Found an account record in Account.tsx onValue`
            //   )
            // );

            const myAccount = snapshot.val() as accountType;
            myAccount.accountId = String(snapshot.key);

            // TODO: Figure out why this doesn't run after the browser has been dormant for a long time.
            // It seems like it should refresh the data, but it doesn't, which leaves the user without
            // valid YNAB tokens.

            dispatch(accountActions.setAccount(myAccount));
            dispatch(accountActions.setRetrieving(false));
          } else {
            // dispatch(
            //   sessionActions.addConsoleMessage(
            //     `Did not find an account record in Account.tsx onValue`
            //   )
            // );

            dispatch(accountActions.setRetrieving(false));
            dispatch(
              sessionActions.setError(
                'Uh oh, we could not find an account for you!'
              )
            );
          }
        }
        // (error) =>
        //   dispatch(
        //     sessionActions.addConsoleMessage(
        //       `The Account.tsx onValue failed ${JSON.stringify(error)}`
        //     )
        //   )
      );

      return () => {
        // dispatch(
        //   sessionActions.addConsoleMessage(
        //     `onValue unsubscribed in Account.tsx (accounts/[accountId])`
        //   )
        // );
        unsubscribe();
      };
    }
  }, [accountId, dispatch, isConnected, isLoggedIn]);

  /**
   * This effect hook will keep track of when we have valid YNAB tokens.  This doesn't
   * mean that the tokens are GOOD, but it means the values we have look legitimate.
   * Certain parts of the application will not execute when we do not have legitimate
   * looking token data.  Don't allow this effect hook to run until I have an
   * accountId and budgetId.  If it runs too early, it will always report false and
   * will create excessive state updates.
   */
  useEffect(() => {
    if (!retrievingAccount && !!accountId && !!budgetId) {
      // TODO: Should I include a check for the expiration date having passed?  Is there any chance that setting that would break something where this hasYnabTokens value is used?
      const hasYnabTokens = !!ynabAccessToken && !!ynabRefreshToken;

      dispatch(accountActions.setHasYnabTokens(hasYnabTokens));
    }
  }, [
    accountId,
    budgetId,
    dispatch,
    retrievingAccount,
    ynabAccessToken,
    ynabRefreshToken,
  ]);

  return <>{props.children}</>;
};

export default Account;
