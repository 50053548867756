import '../styles/globals.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import Script from 'next/script';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

import store from '../store/store';

import Layout from '../components/UI/Layout';
import Ynab from '../components/Ynab';
import { useEffect } from 'react';
import React from 'react';
import Account from '../components/Account';
import User from '../components/User';
import AppReady from '../components/AppReady';
import { useRouter } from 'next/router';
import ProtectedRoute from '../components/ProtectedRoute';

const noAuthRequired = [
  '/',
  '/login',
  '/contact',
  '/signup',
  '/privacy',
  '/permissionsTesting',
];

/**
 * Note about app-wide console color coding:
 * All timer comments should be colored as #f00
 * All authentication comments should be #0a0
 * All user comments should be #00f
 * All account comments should be #0aa
 * All YNAB comments should be #aa0
 */

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    if (document) {
      const html = document.querySelector('html');
      const body = document.querySelector('body');
      const next = document.querySelector('#__next');

      !!html && html.classList.add('h-100');
      !!body && body.classList.add('d-flex');
      !!body && body.classList.add('flex-column');
      !!body && body.classList.add('h-100');

      !!next && next.classList.add('d-flex');
      !!next && next.classList.add('flex-column');
      !!next && next.classList.add('h-100');
    }
  }, []);

  /**
   * The general state flow for prepping the application is this:
   * . Firebase auth will find the authorized user details and in AppReady.tsx
   * we set the userId and isLoggedIn in the user store.
   *
   * . As soon as the userId is set, the effect hook in User.tsx will run
   * and will set the accountId in the account store.
   *
   * . As soon as the accountId is set, the effect hook in
   */

  return (
    <Provider store={store}>
      <AppReady>
        <Ynab>
          <Account>
            <User>
              <Layout>
                <Script
                  src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js"
                  integrity="sha384-OERcA2EqjJCMA+/3y+gxIOqMEjwtxJY7qPCqsdltbNJuaOe923+mo//f6V8Qbsw3"
                  crossOrigin="anonymous"
                />
                <Script src="https://kit.fontawesome.com/8431a3ff7d.js" />

                {noAuthRequired.includes(router.pathname) ? (
                  <Component {...pageProps} />
                ) : (
                  <ProtectedRoute>
                    <Component {...pageProps} />
                  </ProtectedRoute>
                )}
              </Layout>
            </User>
          </Account>
        </Ynab>
      </AppReady>
    </Provider>
  );
};

export default MyApp;
