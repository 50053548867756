import { RequiredError } from 'ynab';
import { FirebaseAuthError, GeneralError, YnabApiError } from '../errorTypes';

/**
 * Typeguard to test an object to see if it is a plain Error object.
 *
 * @param {RequiredError | Error | unknown} error An object to test
 * @returns {boolean} A boolean that indicates a match, or not.
 */
export const isError = (error: Error | unknown): error is Error => {
  return error !== undefined && (error as Error).name !== undefined;
};

/**
 * Typeguard to test an object to see if it is an error from Firebase Auth.
 *
 * @param {unknown} object An object to test
 * @returns {boolean} A boolean that indicates a match, or not.
 *
 * Expects an object with a code and message attribute.  For example:
 * {
 *  code: 'auth/invalid-password',
 *  message: 'The password must be a string with at least 6 characters.'
 * }
 */
export const isFirebaseAuthError = (
  object: unknown
): object is FirebaseAuthError => {
  let isType = false;

  if (object !== undefined) {
    const typedObj = object as FirebaseAuthError;

    if (typedObj.code !== undefined && typedObj.message !== undefined) {
      isType = typedObj.code.startsWith('auth/');
      console.log(
        `isFirebaseError: typedObj.data.code !== undefined, typedObj.data.message !== undefined, starts with "auth/" is ${String(
          isType
        )}`
      );
    }
  }

  return isType;
  // return object !== undefined && (object as FirebaseError).code !== undefined;
};

export const isGeneralError = (object: unknown): object is GeneralError => {
  let isType = false;

  if (object !== undefined) {
    const typedObj = object as GeneralError;

    if (typedObj.isCustomType !== undefined) {
      console.log(`isGeneralError: typedObj.type !== undefined`);
      isType = typedObj.isCustomType; // Will always return true at this point because it's a readonly property
    }
  }

  return isType;
  // return object !== undefined && (object as GeneralError).type !== undefined;
};

/**
 * Typeguard to test an object to see if it is a YnabApiError object from the
 * YNAB API.
 *
 * @param {YnabApiError | unknown} error An object to test
 * @returns {boolean} A boolean that indicates a match, or not.
 */
export const isYnabApiError = (
  error: YnabApiError | unknown
): error is YnabApiError => {
  return error !== undefined && (error as YnabApiError).error.id !== undefined;
};

/**
 * Typeguard to test an object to see if it is a RequiredError object from the
 * YNAB SDK.
 *
 * @param {RequiredError | unknown} error An object to test
 * @returns {boolean} A boolean that indicates a match, or not.
 */
export const isYnabSdkError = (
  error: RequiredError | unknown
): error is RequiredError => {
  return error !== undefined && (error as RequiredError).field !== undefined;
};
