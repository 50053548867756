import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userType } from '../types';
import { userStore } from '../storeTypes';

const initialState: userStore = {
  accountId: '',
  category_group_id: '',
  firstName: '',
  isLoggedInOrUndefined: undefined,
  isLoggedIn: false,
  lastName: '',
  localId: '',
  retrieving: false,
  role: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<userType>) => {
      state.accountId = action.payload.accountId;
      state.category_group_id = action.payload.category_group_id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.localId = action.payload.localId;
      state.role = action.payload.role;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.localId = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      // It's important to set both variables here.  isLoggedInOrUndefined will
      // be undefined initially, which means that the app doesn't yet know if
      // the user is logged in.  It is therefore also an important value to
      // track.  isLoggedIn is simply a shorthand option for cases where it is
      // acceptable to consider undefined as logged out.  For instance when
      // checking before querying the database.  In that case, undefined is
      // effectively the same as false, because we don't want to query the
      // database even when isLoggedInOrUndefined is undefined or false.
      state.isLoggedInOrUndefined = action.payload;
      state.isLoggedIn = action.payload;
    },
    setLoggedOut: (state): void => {
      state.accountId = '';
      state.category_group_id = '';
      state.firstName = '';
      state.isLoggedInOrUndefined = undefined;
      state.isLoggedIn = false;
      state.lastName = '';
      state.localId = '';
      state.retrieving = false;
      state.role = '';
    },
    setRetrieving: (state, action: PayloadAction<boolean>): void => {
      state.retrieving = action.payload;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
