import { NextPage } from 'next';
import Router from 'next/router';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/storeHooks';
import { sessionActions } from '../store/session';

const ProtectedRoute: NextPage = (props) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const isLoggedInOrUndefined = useAppSelector(
    (state) => state.user.isLoggedInOrUndefined
  );

  useEffect(() => {
    // It is necessary to check both isLoggedInOrUndefined and isLoggedIn
    // where the page reloads, such as a refresh or returning to the app
    // from the YNAB reauthorization redirect, isLoggedIn will be false
    // because the onAuthStateChanged event hasn't triggered yet.  As long
    // as isLoggedInOrUndefined is undefined, it means we don't know if the
    // user is logged in or not.  That means it's too early to redirect.
    if (isLoggedInOrUndefined !== undefined && !isLoggedIn) {
      dispatch(
        sessionActions.addConsoleMessage(
          `Redirecting to login from ProtectedRoute.tsx`
        )
      );
      void Router.push('/login');
    }
  }, [dispatch, isLoggedIn, isLoggedInOrUndefined]);

  return <>{isLoggedIn ? props.children : null}</>;
};

export default ProtectedRoute;
