import { onAuthStateChanged } from 'firebase/auth';
import { ref, onValue } from 'firebase/database';
import type { NextPage } from 'next';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/storeHooks';
import { auth, database } from '../lib/firebase';
import { sessionActions } from '../store/session';
import { userActions } from '../store/user';

const AppReady: NextPage = (props) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);
  const categoryGroupsLastUpdated = useAppSelector(
    (state) => state.categoryGroups.lastUpdated
  );
  const categoriesLastUpdated = useAppSelector(
    (state) => state.categories.lastUpdated
  );

  useEffect(() => {
    try {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        // dispatch(
        //   sessionActions.addConsoleMessage(
        //     `onAuthStateChanged subscribed in AppReady.tsx`
        //   )
        // );

        if (user) {
          // dispatch(
          //   sessionActions.addConsoleMessage(
          //     `User IS logged in (AppReady.tsx) ${String(user.email)}`
          //   )
          // );
          dispatch(userActions.setUserId(user.uid));
          dispatch(userActions.setLoggedIn(true));
        } else {
          // dispatch(
          //   sessionActions.addConsoleMessage(
          //     `User IS NOT logged in (AppReady.tsx)`
          //   )
          // );
          dispatch(userActions.setLoggedIn(false));
        }
      });

      return () => {
        // dispatch(
        //   sessionActions.addConsoleMessage(
        //     `onAuthStateChanged unsubscribed in AppReady.tsx`
        //   )
        // );
        unsubscribe();
      };
    } catch (error) {
      // dispatch(
      //   sessionActions.addConsoleMessage(
      //     `The AppReady.tsx onAuthStateChanged failed ${JSON.stringify(error)}`
      //   )
      // );
    }
  }, [dispatch]);

  /**
   * This component watches the app to see if it's "ready."  The app is ready when
   * we have a role for the user (which means they are logged in), and valid YNAB
   * tokens.  We know we have valid tokens when the category groups and categories
   * have been updated.
   */
  useEffect(() => {
    const hasUser = role === 'administrator' || role === 'user';
    const hasYnabData =
      categoryGroupsLastUpdated > 0 && categoriesLastUpdated > 0;

    if (hasUser && hasYnabData) {
      dispatch(sessionActions.setAppReady(true));
    } else {
      dispatch(sessionActions.setAppReady(false));
    }
  }, [categoriesLastUpdated, categoryGroupsLastUpdated, dispatch, role]);

  useEffect(() => {
    // dispatch(
    //   sessionActions.addConsoleMessage(
    //     `onValue subscribed in AppReady.tsx (.info/connected)`
    //   )
    // );

    const connectedRef = ref(database, '.info/connected');

    const unsubscribe = onValue(
      connectedRef,
      (snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val()) {
            // dispatch(
            //   sessionActions.addConsoleMessage(`Connected=true (AppReady.tsx)`)
            // );
            dispatch(sessionActions.setIsConnected(true));

            // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
            // const con = push(userConnection);

            // When I disconnect, remove this device
            // void onDisconnect(con).remove();

            // Write something to the database to log the connection
            // void set(con, true);

            // When I disconnect, update the last time I was seen online
            // void onDisconnect(userLastOnline).set(serverTimestamp());

            // When I disconnect, update the last time I was seen online
            // void onDisconnect(userLastOnline).set(serverTimestamp());
          } else {
            // dispatch(
            //   sessionActions.addConsoleMessage(`Connected=false (AppReady.tsx)`)
            // );
            dispatch(sessionActions.setIsConnected(false));
          }
        }
      }
      // (error) =>
      //   dispatch(
      //     sessionActions.addConsoleMessage(
      //       `The AppReady.tsx onValue failed ${JSON.stringify(error)}`
      //     )
      //   )
    );

    return () => {
      // dispatch(
      //   sessionActions.addConsoleMessage(
      //     `onValue unsubscribed in AppReady.tsx (.info/connected)`
      //   )
      // );
      unsubscribe();
    };
  }, [dispatch]);

  return <>{props.children}</>;
};

export default AppReady;
