import type { NextPage } from 'next';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/storeHooks';
import { userActions } from '../store/user';
import { userType } from '../types';
import { database } from '../lib/firebase';
import { equalTo, onValue, orderByKey, query, ref } from 'firebase/database';
import { accountActions } from '../store/account';
import { sessionActions } from '../store/session';

const User: NextPage = (props) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const isConnected = useAppSelector((state) => state.session.isConnected);
  const userId = useAppSelector((state) => state.user.localId);

  /**
   * This will keep track of whether the user is logged in or not.
   */
  useEffect(() => {
    if (isConnected && isLoggedIn && userId) {
      // dispatch(
      //   sessionActions.addConsoleMessage(
      //     `onValue subscribed in User.tsx (users/[userId])`
      //   )
      // );

      dispatch(userActions.setRetrieving(true));

      // Since the user just became logged in, we need to get the user record
      // Get the user's user object
      const dbRef = query(
        ref(database, `users`),
        orderByKey(),
        equalTo(userId)
      );
      const unsubscribe = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            // dispatch(
            //   sessionActions.addConsoleMessage(`Found a user record (User.tsx)`)
            // );

            const myUser = snapshot.child(userId).val() as userType;
            myUser.localId = userId;

            dispatch(userActions.setUser(myUser));
            dispatch(accountActions.setAccountId(myUser.accountId));
            dispatch(userActions.setRetrieving(false));
          } else {
            // dispatch(
            //   sessionActions.addConsoleMessage(
            //     `Did not find a user record (User.tsx)`
            //   )
            // );

            dispatch(userActions.setRetrieving(false));
            dispatch(
              sessionActions.setError(
                'Uh oh, we could not find a user for you!'
              )
            );
          }
        },
        (error) => {
          dispatch(
            sessionActions.addConsoleMessage(
              `The User.tsx onValue failed ${JSON.stringify(error)}`
            )
          );
          dispatch(
            sessionActions.setError(
              'Uh oh, something went wrong when trying to retrieve your user!'
            )
          );
        }
      );

      return () => {
        // dispatch(
        //   sessionActions.addConsoleMessage(
        //     `onValue unsubscribed in User.tsx (users/[userId])`
        //   )
        // );
        unsubscribe();
      };
    }
  }, [dispatch, isConnected, isLoggedIn, userId]);

  return <>{props.children}</>;
};

export default User;
