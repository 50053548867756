/**
 * A list of possible error types to be used in the GeneralError object type property.
 */
export enum GeneralErrorTypes {
  Unknown = 'Unknown',
  AxiosError = 'AxiosError',
  FirebaseError = 'FirebaseError',
}

/**
 * An object that should be used in all error handling in the app.
 */
export class GeneralError {
  readonly isCustomType: boolean = true;

  devMessage: string;
  errorCode: string;
  httpStatusCode: number;
  originalError: unknown;
  type: GeneralErrorTypes;
  userMessage: string;

  constructor(
    type: GeneralErrorTypes,
    errorCode: string,
    httpStatusCode: number,
    userMessage: string,
    devMessage: string,
    originalError: unknown
  ) {
    this.type = type;
    this.errorCode = errorCode;
    this.httpStatusCode = httpStatusCode;
    this.userMessage = userMessage;
    this.devMessage = devMessage;
    this.originalError = originalError;
  }
}

/**
 * An interface that describes the shape of a FirebaseAuthError
 */
export interface FirebaseAuthError {
  code: string;
  customData: unknown;
  httpResponse?: string;
  message: string;
  name: string;
  serviceCode?: string;
}

/**
 * A class that defines the shape of an error response from the YNAB API.
 *
 * @class YnabApiError
 */
export class YnabApiErrorDetail {
  id: string;
  name: string;
  detail: string;

  constructor(id: string, name: string, detail: string) {
    this.id = id;
    this.name = name;
    this.detail = detail;
  }
}

/**
 * A class that defines the shape of an error response from the YNAB API.
 *
 * @class YnabApiError
 */
export class YnabApiError {
  error: YnabApiErrorDetail;

  constructor(error: YnabApiErrorDetail) {
    this.error = error;
  }
}
