import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatDate } from '../lib/functions';
import { sessionStore } from '../storeTypes';

const initialState: sessionStore = {
  appReady: false,
  autoLogOutDate: 0,
  consoleMessages: [],
  errorMessage: '',
  isConnected: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    addConsoleMessage: (
      state,
      action: PayloadAction<string | string[]>
    ): void => {
      if (typeof action.payload === 'object') {
        for (const message of action.payload) {
          const msg = `${message} (${formatDate(new Date())})`;
          // console.log(msg);
          state.consoleMessages.push(msg);
        }
      } else {
        const msg = `${action.payload} (${formatDate(new Date())})`;
        console.log(msg);
        state.consoleMessages.push(msg);
      }
    },
    setAppReady: (state, action: PayloadAction<boolean>): void => {
      state.appReady = action.payload;
    },
    setAutoLogoutDate: (state, action: PayloadAction<number>) => {
      state.autoLogOutDate = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setIsConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setLoggedOut: (state): void => {
      // Note, it is CRITICAL that you DO NOT reinitialize state.isConnected
      // to its default value of false.  This variable needs to keep its
      // value even when the user logs out, because the app's connection
      // state has nothing to do with being logged in or logged out.
      state.appReady = false;
      state.autoLogOutDate = 0;
      state.errorMessage = '';
    },
  },
});

export const sessionActions = sessionSlice.actions;

export default sessionSlice.reducer;
